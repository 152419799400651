


















import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import FormBase from "@/shared/classes/form/form-base";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import { getNodeApiUrlByVersion } from "@/config";
import FormField from "@/shared/components/form/FormField.vue";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import { CompanyRoutes } from "@/shared/router/company";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import AssignedHseRoutines from "@/shared/modules/assigned-hse-routines/assigned-hse-routines.model";
import _ from "lodash";
import Form from "@/shared/components/form/Form.vue";
import EditorField from "@/shared/classes/form/fields/editor-field";
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { validateFields } from "@/shared/helpers/validate";

@Component({
  components: { PageHeader, Form, FormField },
  methods: { __ }
})
export default class AssignedRoutineCreate extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint(`${getNodeApiUrlByVersion()}/assigned-hse-routines`)
    .setModel(AssignedHseRoutines)
    .setUnsetNonFieldsValues(false)
    .setTranslatable(true)
    .setFields([
      new SearchableField()
        .setKey("hseRoutineCategoryUuid")
        .setEntryKey("hseRoutineCategoryUuid")
        .isRequired()
        .setTitle(__("admin.components.routine-templates.form.category"))
        .loadItems({
          endpoint: `${getNodeApiUrlByVersion()}/hse-routines/categories`,
          value: "uuid",
          title: "name",
          perPage: 20
        })
        .setSize(FieldSizes.half)
        .setOnItemSelect((item: any) => this.form.data.category = item?.meta || null),
      new SearchableField()
        .setKey('responsibleUserUuid')
        .isRequired()
        .setHideDetails(true)
        .loadItems({
          endpoint: `company/${ this.company.slug }/employees`,
          value: 'uuid',
          title: 'full_name',
          perPage: 20,
        })
        .setTitle(__('company.components.hse-routines.selected-assign.form.responsible'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey("name")
        .isRequired()
        .setDense(true)
        .setTranslatable(true)
        .setSize(FieldSizes.half)
        .setTitle(__("admin.components.routine-templates.form.routine-title")),
      new EditorField()
        .setKey("content")
        .setTranslatable(true)
        .setTitle(__("admin.components.routine-templates.create-dialog.form.content"))
        .isRequired(),
    ])
    .setValidate(true, { onlyRegionLangRequired: true })
    .setShowDefaultValidationError(true)
    .setSubmit({
      text: __("general.create")
    })
    .setInjectValues({
      companyUuid: this.company.uuid,
      revisedByPersonUuid: this.user.uuid
    })
    .setChangeDataBeforeSubmit(data => {
      const body = { ...data };
      if (data.category?.isCompanyCategory) {
        delete body.hseRoutineCategoryUuid;
        body.companyHseRoutineCategoryUuid = data.category.uuid;
      }

      return [body]
    })
    .setOnSuccess(this.onSuccess);

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.hseRoutinesIndexTabAssigned });
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __("company.views.hse-routines.assigned-create.form.on-success"),
    });

    this.goBack();
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser];
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }
}
