import Model from '@/shared/classes/model'
import IHseRoutineCategoryResponse from "@/shared/modules/hse-routine-categories/hse-routine-category-response.interface";
import HseRoutineCategory from "@/shared/modules/hse-routine-categories/hse-routine-category.model";
import IAssignedHseRoutinesResponse from "@/shared/modules/assigned-hse-routines/assigned-hse-routines-response.interface";
import IUserResponse from "@/shared/modules/user/user-response.interface";
import User from "@/shared/modules/user/user.model";
import store from '@/shared/store'
import { AuthGetters } from '@/shared/store/auth/auth.getters'

export default class AssignedHseRoutines extends Model<IAssignedHseRoutinesResponse> {
  hseRoutineCategoryUuid!: string
  companyHseRoutineCategoryUuid!: string
  hseRoutineCategory!: IHseRoutineCategoryResponse
  hseRoutineCategoryName!: string
  hseRoutineTemplateUuid!: string
  companyRoutineTemplateUuid!: string
  name!: string
  content!: string
  companyUuid!: string
  responsibleUserUuid!: string
  responsibleUser!: IUserResponse
  responsibleUserName!: string
  version!: number
  revisedByPersonUuid!: string
  revisedByPerson!: IUserResponse
  revisedByPersonName!: string

  transform(data: IAssignedHseRoutinesResponse): void {
    super.transform(data)

    this.hseRoutineCategoryUuid = data.hseRoutineCategoryUuid
    this.companyHseRoutineCategoryUuid = data.companyHseRoutineCategoryUuid
    this.hseRoutineCategoryName = data.hseRoutineCategory?.name || data.companyHseRoutineCategory?.name
    this.hseRoutineTemplateUuid = data.hseRoutineTemplateUuid
    this.companyRoutineTemplateUuid = data.companyRoutineTemplateUuid
    this.name = data.name
    this.content = data.content
    this.companyUuid = data.companyUuid
    this.responsibleUserUuid = data.responsibleUserUuid
    this.responsibleUserName = (data.responsibleUser as any)?.fullName
    this.version = data.version
    this.revisedByPersonUuid = data.revisedByPersonUuid
    this.revisedByPersonName = store.getters[AuthGetters.getUser]?.fullName
    this.revisedByPerson = store.getters[AuthGetters.getUser]

    this.hseRoutineCategory = this.setRelation(HseRoutineCategory, data.hseRoutineCategory)
    this.responsibleUser = this.setRelation(User, data.responsibleUser)
  }
}
